// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth';
import {collection, getFirestore} from  "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB34QzhPNdPk4oYA8j-iXLYY7c40m-eaUo",
  authDomain: "happyymeet.firebaseapp.com",
  projectId: "happyymeet",
  storageBucket: "happyymeet.appspot.com",
  messagingSenderId: "753196796670",
  appId: "1:753196796670:web:522b93dfa729d616d533af",
  measurementId: "G-VQ815CKMGS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firebaseAuth = getAuth(app);
export const firebaseDB = getFirestore(app);

export const usersRef = collection(firebaseDB, "users");
export const meetingsRef = collection(firebaseDB, "meetings");
export const usermeetingRef = collection(firebaseDB, "usermeeting");
export const notificationsRef = collection(firebaseDB, "notifications");