import React, { useState } from 'react';
import Header from '../components/Header';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiTextArea,
  EuiSelect,
  EuiButton,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiImage
} from '@elastic/eui';
import logo from '../assets/logo.png';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Bug: React.FC = () => {
  const [bugTitle, setBugTitle] = useState('');
  const [customBugTitle, setCustomBugTitle] = useState('');
  const [bugDescription, setBugDescription] = useState('');
  const [bugPriority, setBugPriority] = useState('Medium');
  const [isCustomTitle, setIsCustomTitle] = useState(false);
  const [status, setStatus] = useState('');

  const functions = getFunctions();
  const sendBugReport = httpsCallable(functions, 'sendBugReport');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const result = await sendBugReport({
        bugTitle,
        customBugTitle,
        bugDescription,
        bugPriority,
      }) as { data: { success: boolean } };

      if (result.data.success) {
        setStatus('Bug report submitted successfully!');
        setBugTitle('');
        setCustomBugTitle('');
        setBugDescription('');
        setBugPriority('Medium');
      } else {
        setStatus('Failed to submit bug report.');
      }
    } catch (error) {
      console.error('Error submitting bug report:', error);
      setStatus('Failed to submit bug report.');
    }
  };

  const bugTitleOptions = [
    { value: '', text: 'Sélectionner un type de bug' },
    { value: 'Page blanche', text: 'Page blanche' },
    { value: "Erreur d'affichage", text: "Erreur d'affichage" },
    { value: 'Problème de performance', text: 'Problème de performance' },
    { value: "Crash de l'application", text: "Crash de l'application" },
    { value: 'Problème de connexion', text: 'Problème de connexion' },
    { value: 'custom', text: 'Autre (saisir manuellement)' },
  ];

  const handleTitleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setBugTitle(value);
    setIsCustomTitle(value === 'custom');
    if (value !== 'custom') setCustomBugTitle('');
  };

  return (
    <div style={{
      background: 'linear-gradient(-45deg, #0d0d0d, #1a1a1a, #e67e22, #f39c12)',
      backgroundSize: '400% 400%',
      animation: 'gradientAnimation 10s ease infinite',
      width: '100vw',
      height: '100vh',
    }}>
      <Header />
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <EuiFlexItem grow={false}>
          <EuiPanel paddingSize="xl">
            <EuiFlexGroup justifyContent="center" alignItems="center">
              <EuiFlexItem>
                <EuiImage src={logo} alt="logo" size="230px" />
                <EuiSpacer size="xs" />
                <EuiText textAlign="center" grow={false}>
                  <h3>
                    <EuiTextColor>Report</EuiTextColor>
                    <EuiTextColor color="#fca803"> Bug</EuiTextColor>
                  </h3>
                </EuiText>
                <EuiSpacer size="l" />
                <form onSubmit={handleSubmit}>
                  <EuiSelect
                    options={bugTitleOptions}
                    value={bugTitle}
                    onChange={handleTitleChange}
                    aria-label="Sélectionner un type de bug"
                    fullWidth
                  />
                  <EuiSpacer size="m" />
                  {isCustomTitle && (
                    <>
                      <EuiFieldText
                        placeholder="Saisir un titre personnalisé"
                        value={customBugTitle}
                        onChange={(e) => setCustomBugTitle(e.target.value)}
                        aria-label="Titre personnalisé du Bug"
                        fullWidth
                      />
                      <EuiSpacer size="m" />
                    </>
                  )}
                  <EuiTextArea
                    placeholder="Description du Bug"
                    value={bugDescription}
                    onChange={(e) => setBugDescription(e.target.value)}
                    aria-label="Description du Bug"
                    fullWidth
                  />
                  <EuiSpacer size="m" />
                  <EuiText><strong>Priorité</strong></EuiText>
                  <EuiSpacer size="s" />
                  <EuiSelect
                    options={[
                      { value: 'Low', text: 'Faible' },
                      { value: 'Medium', text: 'Moyenne' },
                      { value: 'High', text: 'Élevée' },
                    ]}
                    value={bugPriority}
                    onChange={(e) => setBugPriority(e.target.value)}
                    aria-label="Priorité"
                    fullWidth
                  />
                  <EuiSpacer size="l" />
                  <EuiButton type="submit" fill>
                    Envoyer le Rapport
                  </EuiButton>
                </form>
                {status && <EuiText>{status}</EuiText>}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export default Bug;
