import React, { useState, useEffect } from 'react';
import { EuiImage, EuiPanel, EuiText, EuiSpacer, EuiLink } from '@elastic/eui';
import Header from '../components/Header';
import histoireImage from '../assets/histoire.jpg';
import missionImage from '../assets/mission.jpg';
import visionImage from '../assets/vision.jpg';
import valeursImage from '../assets/valeurs.jpg';

type Section = {
  title: string;
  content: string;
  image: string;
};

const sections: { [key: string]: Section } = {
  histoire: {
    title: 'Histoire du Projet',
    content: `HappyMeet est né d'un besoin croissant de solutions de visioconférence simples, accessibles et communautaires dans un monde où le travail à distance, l'apprentissage en ligne et les interactions sociales numériques sont devenus la norme. Initialement conçu comme un projet personnel en 2023, HappyMeet visait à répondre à une lacune dans le marché des plateformes de visioconférence : la nécessité d'une solution facile à utiliser, ouverte à tous et centrée sur l'interaction communautaire.`,
    image: histoireImage,
  },
  mission: {
    title: 'Mission de HappyMeet',
    content: `La mission de HappyMeet est de démocratiser la communication en ligne en fournissant une plateforme de visioconférence facile à utiliser, accessible à tous, et conçue pour favoriser les interactions communautaires.`,
    image: missionImage,
  },
  vision: {
    title: 'Vision de HappyMeet',
    content: `Notre vision est de devenir la plateforme de référence pour la visioconférence communautaire, où les utilisateurs peuvent se connecter, collaborer et partager en toute simplicité.`,
    image: visionImage,
  },
  valeurs: {
    title: 'Valeurs de HappyMeet',
    content: `Simplicité : Nos utilisateurs doivent pouvoir organiser et participer à des réunions sans se soucier des complexités techniques. Accessibilité : HappyMeet est conçu pour être utilisé par tous, indépendamment de leurs compétences techniques. Communauté : Nous croyons en la création d'un espace où les utilisateurs peuvent se connecter et collaborer. Ouverture : Nous prônons la transparence et l'ouverture dans notre technologie et nos pratiques. Innovation : Nous nous engageons à innover continuellement pour améliorer l'expérience de nos utilisateurs.`,
    image: valeursImage,
  }
};

const PagePreContact = () => {
  const [sectionIndex, setSectionIndex] = useState<number>(0);
  const [contentStates, setContentStates] = useState<{ [key: string]: string }>({
    histoire: '',
    mission: '',
    vision: '',
    valeurs: '',
  });

  const sectionKeys = Object.keys(sections);

  useEffect(() => {
    const displaySectionContent = (sectionKey: string) => {
      const section = sections[sectionKey];
      const words = section.content.split(' ');
      let wordIndex = 0;
      const delay = 100; // Delay between each word in milliseconds

      const interval = setInterval(() => {
        if (wordIndex < words.length) {
          setContentStates(prev => ({
            ...prev,
            [sectionKey]: `${prev[sectionKey]} ${words[wordIndex]}`
          }));
          wordIndex += 1;
        } else {
          clearInterval(interval);
        }
      }, delay);
    };

    if (sectionIndex < sectionKeys.length) {
      const currentKey = sectionKeys[sectionIndex];
      displaySectionContent(currentKey);
      
      const nextSectionTimeout = setTimeout(() => {
        setSectionIndex(prevIndex => prevIndex + 1);
      }, 5000 + (sections[sectionKeys[sectionIndex]].content.split(' ').length * 100)); // Wait until current section is done plus extra delay

      return () => clearTimeout(nextSectionTimeout); // Clean up timeout on component unmount
    }
  }, [sectionIndex]);

  const headerStyle: React.CSSProperties = {
    padding: '20px',
    textAlign: 'center',
    fontSize: '2.5em',
    fontWeight: 'bold',
    fontFamily: 'Georgia, serif',
    color: '#333',
    textTransform: 'uppercase',
  };

  const sectionTitleStyle: React.CSSProperties = {
    fontSize: '2em',
    fontFamily: 'Georgia, serif',
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: '#222',
  };

  const sectionTextStyle: React.CSSProperties = {
    fontSize: '1.2em',
    fontFamily: 'Georgia, serif',
    color: '#555',
    lineHeight: '1.6',
    marginTop: '10px',
  };

  const sectionImageStyle: React.CSSProperties = {
    maxWidth: '100%',
    height: 'auto',
    margin: '0 auto',
  };

  return (
    <div>
      <Header />
      <div style={{ padding: '20px', boxSizing: 'border-box' }}>
        <EuiText style={headerStyle}>
          <h1>Histoire, Mission, Vision, Valeurs</h1>
        </EuiText>
        <main>
          {sectionKeys.map((key) => (
            <section id={key} key={key} style={{ marginBottom: '40px' }}>
              <EuiPanel paddingSize="l">
                <EuiImage
                  size="l"
                  hasShadow
                  allowFullScreen
                  src={sections[key].image}
                  alt={sections[key].title}
                  style={sectionImageStyle}
                />
                <EuiSpacer size="m" />
                <EuiText>
                  <h2 style={sectionTitleStyle}>{sections[key].title}</h2>
                  <p style={sectionTextStyle}>{contentStates[key]}</p>
                </EuiText>
              </EuiPanel>
              <EuiSpacer size="xl" />
            </section>
          ))}
          
          <div style={{ textAlign: 'center', margin: '20px' }}>
            <EuiText>
              <p>Souhaitez-vous contacter l'équipe de HappyMeet ou signaler un bug ?</p>
              <EuiLink href="/contactteam" style={{ margin: '0 10px' }}>Contacter l'équipe</EuiLink>
              <EuiLink href="/report" style={{ margin: '0 10px' }}>Signaler un bug</EuiLink>
            </EuiText>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PagePreContact;
