import {
    EuiBadge,
    EuiBasicTable,
    EuiButtonIcon,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
    EuiSpacer,
  } from "@elastic/eui";
  import { getDocs, query, where } from "firebase/firestore";
  import moment from "moment";
  import React, { useEffect, useState, useCallback } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { useAppSelector } from "../app/hooks";
  import EditFlyout from "../components/EditFlyout";
  import Header from "../components/Header";
  import useAuth from "../hooks/useAuth";
  import { meetingsRef } from "../utils/FirebaseConfig";
  import { MeetingType } from "../utils/types";
  import { useKKiaPay } from "kkiapay-react";
  import { Line } from 'react-chartjs-2';
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartData
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  export default function Wallet() {
    useAuth();
    const userInfo = useAppSelector((happymeet) => happymeet.auth.userInfo);
    const [meetings, setMeetings] = useState<Array<MeetingType>>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);// état our récupérer le prix total
    const [showEditFlyout, setShowEditFlyout] = useState(false);
    const initialChartData: ChartData<'line'>={
      labels: [] as string[],
      datasets: [{
        label: 'Meeting Price',
        data: [] as number[],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      }]
    };
    const [chartData, setChartData] = useState<ChartData<'line'>>(initialChartData);
    const { openKkiapayWidget, addSuccessListener } = useKKiaPay();
    const navigate = useNavigate();
  
    const [editMeeting, setEditMeeting] = useState<MeetingType>();
    const getMyMeetings = useCallback(async () => {
      const firestoreQuery = query(
        meetingsRef,
        where("createdBy", "==", userInfo?.uid)
      );
      const fetchedMeetings = await getDocs(firestoreQuery);
      if (fetchedMeetings.docs.length) {
        const myMeetings: Array<MeetingType> = [];
        fetchedMeetings.forEach((meeting) => {
          myMeetings.push({
            docId: meeting.id,
            ...(meeting.data() as MeetingType),
          });
        });
        setMeetings(myMeetings);
        calculatteTotalPrice(myMeetings);
      }
    }, [userInfo?.uid]);
  
    const calculatteTotalPrice = (meetings : Array<MeetingType>) => {
      const total = meetings.reduce((sum, meeting)=> sum + meeting.price, 0)
      setTotalPrice(total);
    }; //fonction pour calculer le prix total des meetings par utilisateur après l'ajout d'un nouveau meeting
  
    useEffect(() => {
      if (userInfo) getMyMeetings();
    }, [userInfo, getMyMeetings]);
  
    const openEditFlyout = (meeting: MeetingType) => {
      setShowEditFlyout(true);
      setEditMeeting(meeting);
    };
  
    const closeEditFlyout = (dataChanged = false) => {
      setShowEditFlyout(false);
      setEditMeeting(undefined);
      if (dataChanged) getMyMeetings();
    };
  
    /*const formatPrice = (price: number) => {
      return price.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    };*/

    const financeData = meetings.map(meeting => ({ 
      date: moment(meeting.meetingDate).format('DD-MM-YYYY'),
      price: meeting.price,
    }));

    const filterChartData = (period: string) => {
      const now = moment();
      let filteredData: Array<{ date: string, price: number}> = [];
    
      switch (period) {
        case '1d':
          filteredData = financeData.filter(data => moment(data.date, 'DD-MM-YYYY').isAfter(now.subtract(1, 'days')));
          break;
        case '7d':
          filteredData = financeData.filter(data => moment(data.date, 'DD-MM-YYYY').isAfter(now.subtract(7, 'days')));
          break;
        case '30d':
          filteredData = financeData.filter(data => moment(data.date, 'DD-MM-YYYY').isAfter(now.subtract(30, 'days')));
          break;
        case '1y':
          filteredData = financeData.filter(data => moment(data.date, 'DD-MM-YYYY').isAfter(now.subtract(1, 'years')));
          break;
        default:
          filteredData = financeData;
      }
    
      filteredData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  
      updateChart(filteredData);
    };
      
    const updateChart = (filteredData: Array<{ date: string, price: number}>) => {
      const newChartData: ChartData <'line'> ={
        labels: filteredData.map(data => data.date),
        datasets: [{
          label: 'Meeting Price',
          data: filteredData.map(data => data.price),
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
        }]
      };
    
      setChartData(newChartData);
    };
    const meetingColumns = [
      {
        field: "meetingName",
        name: "Meeting Name",
      },
      {
        field: "meetingType",
        name: "Meeting Type",
      },
      {
        field: "meetingDate",
        name: "Meeting Date",
      },
      {
        field: "price",
        name: "Meeting Price",
      },
      {
        field: "",
        name: "Status",
        render: (meeting: MeetingType) => {
          const openkkiapay = (currentMeeting: MeetingType) => {
            openKkiapayWidget({
              amount: meeting.price,
              key: 'aba23e000a4211eeabbab72ba84a8978',
              sandbox: true,
              name: userInfo?.name,
              email: userInfo?.email,
              //callback: `${window.location.origin}/join`+meeting.meetingId,
            });
            addSuccessListener(response => {
              console.log("Entré dans success avec comme id  : ", response.transactionId);
              window.open("/join/"+meeting.meetingId, "_blank");
            });
          };
          if (meeting.status) {
            if (meeting.meetingDate === moment().format("L")) {
              return (
                <EuiBadge color="success">
                  <Link
                    to={`/join/${meeting.meetingId}`}
                    style={{ color: "black" }}
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Join Now
                  </Link>
                </EuiBadge>
              );
            } else if (
              moment(meeting.meetingDate).isBefore(moment().format("L"))
            ) {
              return <EuiBadge color="default">Ended</EuiBadge>;
            } else if (moment(meeting.meetingDate).isAfter()) {
              return <EuiBadge color="primary">Upcoming</EuiBadge>;
            }
          } else return <EuiBadge color="danger">Cancelled</EuiBadge>;
        },
      },
      {
        field: "",
        name: "Relaunch",
        width: "10%",
        render: (meeting: MeetingType) => {
          const relaunchMeeting = (meeting: MeetingType) => {
            const currentTime = moment();
            const meetingEndTime = moment(meeting.meetingDate);
            const diffMin = currentTime.diff(meetingEndTime, 'minutes')
            return diffMin>=60;
          };
    
          return (
            <EuiButtonIcon
              aria-label="meeting-relaunch"
              iconType="refresh"
              color="primary"
              display="base"
              isDisabled={!relaunchMeeting(meeting)}
              onClick={() => openEditFlyout(meeting)}
            />
          );
        },
      },
    ];
  
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Header />
        <EuiFlexGroup justifyContent="center" style={{ marginTop: "2rem" }}>
  <EuiFlexItem style={{ width: '300px', height: '500px' }}>
    <EuiPanel>
      <EuiText textAlign="center">
        <h3>Évolution des Finances</h3>
      </EuiText>
      <EuiSpacer size="xl" />
      
      <EuiFlexGroup justifyContent="center" gutterSize="s">
        <EuiButtonEmpty
          onClick={() => filterChartData('1d')}
          size="s"
        >
          1j
        </EuiButtonEmpty>
        <EuiButtonEmpty
          onClick={() => filterChartData('7d')}
          size="s"
        >
          7j
        </EuiButtonEmpty>
        <EuiButtonEmpty
          onClick={() => filterChartData('30d')}
          size="s"
        >
          30j
        </EuiButtonEmpty>
        <EuiButtonEmpty
          onClick={() => filterChartData('1y')}
          size="s"
        >
          1an
        </EuiButtonEmpty>
      </EuiFlexGroup>
      
      <div style={{ width: '100%', height: '400px', marginTop: '1rem' }}>
        <Line 
          data={chartData} 
          options={{ 
            maintainAspectRatio: false,
            scales: {
                      y: {
                           beginAtZero: true, 
                          }
                      }
           }} 
        />
      </div>
      
    </EuiPanel>
  </EuiFlexItem>
</EuiFlexGroup>
      <EuiFlexGroup justifyContent="center" style={{ marginTop: "2rem" }}>
        <EuiFlexItem>
          <EuiPanel>
            <EuiText textAlign="center">
              <h3> Total Price: {/*formatPrice*/(totalPrice)}</h3>
            </EuiText>
            <EuiSpacer size="xl" />
            <EuiBasicTable items={meetings} columns={meetingColumns} />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
        {showEditFlyout && (
          <EditFlyout closeFlyout={closeEditFlyout} meeting={editMeeting!} />
        )}
      </div>
    );
  }
  