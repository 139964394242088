import React, { useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiFieldText, EuiTextArea, EuiButton, EuiPanel, EuiSpacer, EuiText, EuiProvider, EuiTextColor, EuiImage } from '@elastic/eui';
import logo from '../assets/logo.png';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseApp } from 'firebase/app';

interface SendContactEmailResponse {
  success: boolean;
}

const ContactPage: React.FC = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const functions = getFunctions(); // Obtenez une instance des fonctions Firebase
  const sendContactEmail = httpsCallable(functions, 'sendContactEmail');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const result = await sendContactEmail({ name, lastName, message }) as { data: SendContactEmailResponse };
      if (result.data.success) {
        setStatus('Email sent successfully');
        setName('');
        setLastName('');
        setMessage('');
      } else {
        setStatus('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setStatus('Failed to send email');
    }
  };

  return (
    <EuiProvider colorMode="dark">
      <EuiFlexGroup
        justifyContent="center"
        alignItems="center"
        style={{ width: '100vw', height: '100vh' }}
      >
        <EuiFlexItem grow={false}>
          <EuiPanel paddingSize="xl">
            <EuiFlexGroup justifyContent="center" alignItems="center">
              <EuiFlexItem>
                <EuiImage src={logo} alt="logo" size="230px" />
                <EuiSpacer size="xs" />
                <EuiText textAlign="center" grow={false}>
                  <h3>
                    <EuiTextColor>Contact</EuiTextColor>
                    <EuiTextColor color="#fca803"> Us</EuiTextColor>
                  </h3>
                </EuiText>
                <EuiSpacer size="l" />

                <form onSubmit={handleSubmit}>
                  <EuiFieldText
                    placeholder="First Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    aria-label="First Name"
                    fullWidth
                  />
                  <EuiSpacer size="m" />
                  <EuiFieldText
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    aria-label="Last Name"
                    fullWidth
                  />
                  <EuiSpacer size="m" />
                  <EuiTextArea
                    placeholder="Your Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    aria-label="Your Message"
                    fullWidth
                  />
                  <EuiSpacer size="m" />
                  <EuiButton type="submit" fill>
                    Send Message
                  </EuiButton>
                </form>
                {status && <EuiText>{status}</EuiText>}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiProvider>
  );
};

export default ContactPage;
