import {
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiButtonIcon,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
} from "@elastic/eui";

import { addDoc, collection, getDocs, limit, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";

import { firebaseDB, meetingsRef, usermeetingRef } from "../utils/FirebaseConfig";
import { MeetingType, UserMettingType } from "../utils/types";
import { useKKiaPay } from "kkiapay-react";
import useToast from "../hooks/useToast";
import { BadgePlayJoin } from "./BadgePlayJoin";

export default function Meeting() {
  useAuth();
  const userInfo = useAppSelector((happymeet) => happymeet.auth.userInfo);
  const [meetings, setMeetings] = useState<Array<MeetingType>>([]);
  const [usermeeting, setUserMeeting] = useState<Array<UserMettingType>>([]);
  const { openKkiapayWidget, addSuccessListener } = useKKiaPay();
  const navigate = useNavigate();
  const [createUserMeetingToast] = useToast();

  useEffect(() => {
    const getMyMeetings = async () => {
      const firestoreQuery = query(meetingsRef);
      const fetchedMeetings = await getDocs(firestoreQuery);
      if (fetchedMeetings.docs.length) {
        const myMeetings: Array<MeetingType> = [];
        fetchedMeetings.forEach((meeting) => {
          const data = meeting.data() as MeetingType;
          if (data.createdBy === userInfo?.uid || data.meetingType === "anyone-can-join") {
            myMeetings.push(data);
          } else {
            const index = data.invitedUsers.findIndex(
              (user: string) => user === userInfo?.uid
            );
            if (index !== -1) {
              myMeetings.push(data);
            }
          }
        });
        setMeetings(myMeetings);
      }
    };
    if (userInfo) getMyMeetings();
  }, [userInfo]);

  // Fonction pour créer une réunion instantanée
  const createInstantMeeting = async () => {
    const newMeeting = {
      meetingName: "Instant Meeting",
      createdBy: userInfo?.uid,
      meetingType: "anyone-can-join",
      meetingDate: moment().format("L"),
      price: 0,
      status: true,
      meetingId: "instant-" + Date.now(),
    };

    await addDoc(collection(firebaseDB, "meetings"), newMeeting);
    navigate(`/join/${newMeeting.meetingId}`);  // Rediriger l'utilisateur vers la réunion instantanée
  };

  const meetingColumns = [
    {
      field: "meetingName",
      name: "Meeting Name",
    },
    {
      field: "meetingType",
      name: "Meeting Type",
    },
    {
      field: "meetingDate",
      name: "Meeting Date",
    },
    {
      field: "price",
      name: "Meeting Price",
    },
    {
      field: "",
      name: "Status",
      render: (meeting: MeetingType) => {
        if (meeting.status) {
          if (meeting.meetingDate === moment().format("L")) {
            if (meeting.price > 0) {
              if (meeting.createdBy === userInfo?.uid) {
                return (
                  <EuiBadge color="success">
                    <Link to={`/join/${meeting.meetingId}`} target="_blank" rel="noopener noreferrer">
                      Join Now
                    </Link>
                  </EuiBadge>
                );
              } else {
                return <BadgePlayJoin meeting={meeting} userInfo={userInfo} action={() => openKkiapayWidget({ amount: meeting.price })} />;
              }
            } else {
              return (
                <EuiBadge color="success">
                  <Link to={`/join/${meeting.meetingId}`} target="_blank" rel="noopener noreferrer">
                    Join Now
                  </Link>
                </EuiBadge>
              );
            }
          } else if (moment(meeting.meetingDate).isBefore(moment().format("L"))) {
            return <EuiBadge color="default">Ended</EuiBadge>;
          } else {
            return <EuiBadge color="primary">Upcoming</EuiBadge>;
          }
        } else return <EuiBadge color="danger">Cancelled</EuiBadge>;
      },
    },
  ];

  return (
    <div style={{ display: "flex", height: "100vh", flexDirection: "column" }}>
      <Header />
      <EuiFlexGroup justifyContent="center" style={{ margin: "1rem" }}>
        <EuiFlexItem>
          <EuiPanel>
            {/* Ajouter le bouton pour démarrer une réunion instantanée */}
            <EuiButton fill onClick={createInstantMeeting} style={{ marginBottom: "1rem" }}>
              Start Instant Meeting
            </EuiButton>
            <EuiBasicTable items={meetings} columns={meetingColumns} />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
