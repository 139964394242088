import {
  EuiBadge,
  EuiBasicTable,
  EuiButtonIcon,
  EuiCopy,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiText,
  EuiSpacer,
} from "@elastic/eui";
import { getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import EditFlyout from "../components/EditFlyout";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";
import { meetingsRef } from "../utils/FirebaseConfig";
import { MeetingType } from "../utils/types";
import { useKKiaPay } from "kkiapay-react";

export default function MyMeetings() {
  useAuth();
  const userInfo = useAppSelector((happymeet) => happymeet.auth.userInfo);
  const [meetings, setMeetings] = useState<Array<MeetingType>>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);// état our récupérer le prix total
  const [showEditFlyout, setShowEditFlyout] = useState(false);
  const { openKkiapayWidget, addSuccessListener } = useKKiaPay();
  const navigate = useNavigate();

  const [editMeeting, setEditMeeting] = useState<MeetingType>();
  const getMyMeetings = useCallback(async () => {
    const firestoreQuery = query(
      meetingsRef,
      where("createdBy", "==", userInfo?.uid)
    );
    const fetchedMeetings = await getDocs(firestoreQuery);
    if (fetchedMeetings.docs.length) {
      const myMeetings: Array<MeetingType> = [];
      fetchedMeetings.forEach((meeting) => {
        myMeetings.push({
          docId: meeting.id,
          ...(meeting.data() as MeetingType),
        });
      });
      setMeetings(myMeetings);
      calculatteTotalPrice(myMeetings);
    }
  }, [userInfo?.uid]);

  const calculatteTotalPrice = (meetings : Array<MeetingType>) => {
    const total = meetings.reduce((sum, meeting)=> sum + meeting.price, 0)
    setTotalPrice(total);
  }; //fonction pour calculer le prix total des meetings par utilisateur après l'ajout d'un nouveau meeting

  useEffect(() => {
    if (userInfo) getMyMeetings();
  }, [userInfo, getMyMeetings]);

  const openEditFlyout = (meeting: MeetingType) => {
    setShowEditFlyout(true);
    setEditMeeting(meeting);
  };

  const closeEditFlyout = (dataChanged = false) => {
    setShowEditFlyout(false);
    setEditMeeting(undefined);
    if (dataChanged) getMyMeetings();
  };

  const formatPrice = (price: number) => {
    return price.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
  };

  const meetingColumns = [
    {
      field: "meetingName",
      name: "Meeting Name",
    },
    {
      field: "meetingType",
      name: "Meeting Type",
    },
    {
      field: "meetingDate",
      name: "Meeting Date",
    },
    {
      field: "price",
      name: "Meeting Price",
    },
    {
      field: "",
      name: "Status",
      render: (meeting: MeetingType) => {
        const openkkiapay = () => {
          openKkiapayWidget({
            amount: meeting.price,
            key: 'aba23e000a4211eeabbab72ba84a8978',
            sandbox: true,
            name: userInfo?.name,
            email: userInfo?.email,
            //callback: `${window.location.origin}/join`+meeting.meetingId,
          });
          addSuccessListener(response => {
            console.log("Entré dans success avec comme id  : ", response.transactionId);
            window.open("/join/"+meeting.meetingId, "_blank");
          });
        };
        if (meeting.status) {
          if (meeting.meetingDate === moment().format("L")) {
            return (
              <EuiBadge color="success">
                <Link
                  to={`/join/${meeting.meetingId}`}
                  style={{ color: "black" }}
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Join Now
                </Link>
              </EuiBadge>
            );
          } else if (
            moment(meeting.meetingDate).isBefore(moment().format("L"))
          ) {
            return <EuiBadge color="default">Ended</EuiBadge>;
          } else if (moment(meeting.meetingDate).isAfter()) {
            return <EuiBadge color="primary">Upcoming</EuiBadge>;
          }
        } else return <EuiBadge color="danger">Cancelled</EuiBadge>;
      },
    },
    {
      field: "",
      name: "Edit",
      width: "5%",
      render: (meeting: MeetingType) => {
        return (
          <EuiButtonIcon
            aria-label="meeting-edit"
            iconType="indexEdit"
            color="danger"
            display="base"
            isDisabled={
              moment(meeting.meetingDate).isBefore(moment().format("L")) ||
              !meeting.status
            }
            onClick={() => openEditFlyout(meeting)}
          />
        );
      },
    },
    {
      field: "meetingId",
      name: "Copy Link",
      width: "5%",
      render: (meetingId: string) => {
        return (
          <EuiCopy
            textToCopy={`${process.env.REACT_APP_HOST}/join/${meetingId}`}
          >
            {(copy: any) => (
              <EuiButtonIcon
                iconType="copy"
                onClick={copy}
                display="base"
                aria-label="meeting-copy"
              />
            )}
          </EuiCopy>
        );
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Header />
      <EuiFlexGroup justifyContent="center" style={{ margin: "1rem" }}>
        <EuiFlexItem>
          <EuiPanel>
            <EuiText textAlign="center">
              <h3> Total Price: {formatPrice(totalPrice)}</h3>
            </EuiText>
            <EuiSpacer size="xl" />
            <EuiBasicTable items={meetings} columns={meetingColumns} />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      {showEditFlyout && (
        <EditFlyout closeFlyout={closeEditFlyout} meeting={editMeeting!} />
      )}
    </div>
  );
}
