import {
  EuiButtonIcon,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiText,
  EuiTextColor,
  EuiPopover,
  EuiNotificationBadge,
} from "@elastic/eui";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { changeTheme } from "../app/slices/AuthSlice";
import { markAllNotificationsAsRead, clearUserNotifications } from "../app/slices/NotificationsSlice";
import { firebaseAuth, firebaseDB } from "../utils/FirebaseConfig";
import moment from "moment";
import { addDoc, collection } from "firebase/firestore";

// Typage simplifié pour les notifications
interface Notification {
  id: string;
  message: string;
  date: string;
  read: boolean;
  userId: string;
}

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userName = useAppSelector((state) => state.auth.userInfo?.name);
  const isDarkTheme = useAppSelector((state) => state.auth.isDarkTheme);
  const [breadCrumbs, setBreadCrumbs] = useState<Array<{ text: string }>>([
    { text: "Dashboard" },
  ]);

  const [isNotificationPopoverOpen, setIsNotificationPopoverOpen] = useState(false);
  const currentUserId = useAppSelector((state) => state.auth.userInfo?.uid || "");

  const notifications = useAppSelector((state) =>
    state.notifications.filter((n: Notification) => n.userId === currentUserId)
  );
  const unreadNotificationsCount = notifications.filter(n => !n.read).length;

  const toggleNotificationPopover = () => {
    setIsNotificationPopoverOpen(!isNotificationPopoverOpen);

    if (!isNotificationPopoverOpen) {
      dispatch(markAllNotificationsAsRead(currentUserId));
    }
  };

  const closeNotificationPopover = () => {
    setIsNotificationPopoverOpen(false);
  };

  const clearNotifications = () => {
    dispatch(clearUserNotifications(currentUserId));
  };

  const logout = () => {
    signOut(firebaseAuth);
  };

  const invertTheme = () => {
    const theme = localStorage.getItem("happymeet-theme");
    localStorage.setItem("happymeet-theme", theme === "light" ? "dark" : "light");
    dispatch(changeTheme({ isDarkTheme: !isDarkTheme }));
  };

  const createInstantMeeting = async () => {
    const newMeeting = {
      meetingName: "Instant Meeting",
      createdBy: currentUserId,
      meetingType: "anyone-can-join",
      meetingDate: moment().format("L"),
      price: 0,
      status: true,
      meetingId: "instant-" + Date.now(),
    };

    try {
      await addDoc(collection(firebaseDB, "meetings"), newMeeting);
      navigate(`/join/${newMeeting.meetingId}`);  // Rediriger l'utilisateur vers la réunion instantanée
    } catch (error) {
      console.error("Erreur lors de la création du meeting :", error);
    }
  };

  const section = [
    {
      items: [
        <Link to="/">
          <EuiText>
            <h2 style={{ padding: "0 1vw" }}>
              <EuiTextColor color="#fca803">Happymeet</EuiTextColor>
            </h2>
          </EuiText>
        </Link>,
      ],
    },
    {
      items: [
        <>
          {userName ? (
            <EuiText>
              <h3>
                <EuiTextColor color="white">Hello, </EuiTextColor>
                <EuiTextColor color="#fca803">{userName}</EuiTextColor>
              </h3>
            </EuiText>
          ) : null}
        </>,
      ],
    },
    {
      items: [
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="row"
          style={{ gap: "2vw" }}
        >
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiPopover
              button={
                <div style={{ position: "relative" }}>
                  <EuiButtonIcon
                    onClick={toggleNotificationPopover}
                    iconType="bell"
                    display="fill"
                    size="s"
                    color="primary"
                    aria-label="notification-button"
                  />
                  {unreadNotificationsCount > 0 && (
                    <EuiNotificationBadge
                      style={{
                        position: "absolute",
                        top: -5,
                        right: -5,
                      }}
                    >
                      {unreadNotificationsCount}
                    </EuiNotificationBadge>
                  )}
                </div>
              }
              isOpen={isNotificationPopoverOpen}
              closePopover={closeNotificationPopover}
            >
              <div style={{ padding: "10px" }}>
                <EuiText size="s">
                  {notifications.length > 0 ? (
                    notifications.map((n) => (
                      <div
                        key={n.id}
                        style={{
                          marginBottom: "10px",
                          borderBottom: "1px solid #ccc",
                          paddingBottom: "5px",
                        }}
                      >
                        <p>{n.message}</p>
                        <small>{new Date(n.date).toLocaleString()}</small>
                      </div>
                    ))
                  ) : (
                    "You have no new notifications."
                  )}
                </EuiText>
                {notifications.length > 0 && (
                  <EuiButton
                    size="s"
                    color="danger"
                    fullWidth
                    onClick={clearNotifications}
                    style={{ marginTop: "10px" }}
                  >
                    Clear Notifications
                  </EuiButton>
                )}
              </div>
            </EuiPopover>
          </EuiFlexItem>

          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiButton
              fill
              color="primary"
              onClick={createInstantMeeting}
              style={{ marginLeft: "1rem" }}
            >
              🕒 Meeting Instantané
            </EuiButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            {isDarkTheme ? (
              <EuiButtonIcon
                onClick={invertTheme}
                iconType="sun"
                display="fill"
                size="s"
                color="warning"
                aria-label="theme-button-light"
              />
            ) : (
              <EuiButtonIcon
                onClick={invertTheme}
                iconType="moon"
                display="fill"
                size="s"
                color="primary"
                aria-label="theme-button-dark"
              />
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiButtonIcon
              onClick={() => navigate('/pageprecontact')}
              iconType="email"
              display="fill"
              size="s"
              color="primary"
              aria-label="contact-button"
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiButtonIcon
              onClick={logout}
              iconType="lock"
              display="fill"
              size="s"
              aria-label="logout-button"
            />
          </EuiFlexItem>
        </EuiFlexGroup>,
      ],
    },
  ];

  return (
    <>
      <EuiHeader
        style={{ minHeight: "8vh" }}
        theme="dark"
        sections={section}
      />
      <EuiHeader
        style={{ minHeight: "8vh" }}
        sections={[
          {
            breadcrumbs: breadCrumbs,
          },
        ]}
      />
    </>
  );
}
