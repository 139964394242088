import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setToasts } from "../app/slices/MeetingSlice";
import { setUserMeetingToasts } from "../app/slices/UserMeetingSlice";

type ToastType = {
  id: string;
  title: string;
  color: "success" | "primary" | "warning" | "danger" | undefined;
};

function useToast(): [
  (toast: { title: string; type: ToastType["color"] }) => void,
  (toast: { title: string; type: ToastType["color"] }) => void,
  (id: string) => void,
  ToastType[]
] {
  const toasts = useAppSelector((state) => state.meetings.toasts);
  const userMeetingToasts = useAppSelector((state) => state.usermeeting.toasts);
  const dispatch = useAppDispatch();

  const createToast = ({ title, type }: { title: string; type: ToastType["color"] }) => {
    dispatch(
      setToasts(
        toasts.concat({
          id: new Date().toISOString(),
          title,
          color: type,
        })
      )
    );
  };

  const createUserMeetingToast = ({ title, type }: { title: string; type: ToastType["color"] }) => {
    dispatch(
      setUserMeetingToasts(
        userMeetingToasts.concat({
          id: new Date().toISOString(),
          title,
          color: type,
        })
      )
    );
  };

  const removeToast = (id: string) => {
    dispatch(setToasts(toasts.filter((toast) => toast.id !== id)));
  };

  return [createToast, createUserMeetingToast, removeToast, toasts];
}

export default useToast;
